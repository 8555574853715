// import { apiMixin } from '../../../../mixins/apiMixin'
import { addressUtilsMixin } from '../../../shared/mixins/addressUtilsMixin';
import { getItem } from '../../../../api/api-methods';
import { mapState } from 'vuex';
import { utilsMixin } from '../../../shared/mixins/utilsMixin';
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';

import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnFormSubtitle from '../../../shared/components/KnFormSubtitle.vue';
import KnFormTitle from '../../../shared/components/KnFormTitle.vue';
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
import KnSelect from '../../../shared/components/KnSelect.vue';
import KnTextField from '../../../shared/components/KnTextField.vue';

import { fetchDebtsByStudent, postDebt, putDebt } from '../../helpers/utilsDebits';
import { fetchIncomeByStudent } from '../../helpers/utilsIncome';
import { financialStatusUtilsMixin } from '../../mixins/FinancialStatusUtilsMixin';
import { BASE_FINANCIAL_STATUS } from '../../helpers/states';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';
import { fetchInvoiceByStudent } from '../../helpers/utilsInvoice';
import { StudentUtils } from '../../mixins/StudentUtilsMixin';

import { canAdd, canChange } from '../../../shared/helpers/permissionsUtils';

export default {
  components: {
    KnFormSubtitle,
    KnFormActionButtons,
    KnLocalAlert,
    KnSelect,
    KnFormTitle,
    KnTextField,
  },
  mixins: [
    addressUtilsMixin,
    financialStatusUtilsMixin,
    paginationMixin,
    StudentUtils,
    utilsMixin,
    validationFormMixin,
  ],
  props: {},

  data() {
    return {
      loading: false,
      isCharge: false,
      valid: true,
      tableTitle: undefined,
      headers: [
        { text: 'Fecha', value: 'fecha_elaboracion', class: 'purple--text' },
        { text: 'Concepto', value: 'categoria.dato', class: 'purple--text' },
        { text: 'Monto', value: 'total_ingreso', class: 'purple--text' },
        { text: '', value: 'acciones' },
      ],
      items: [],
      studentId: null,
      dateRange: {
        from: '',
        to: '',
      },
      incomeItems: [],
      debtsItems: [],
      coins: [],
      penalties: [],
      promptPaymentDiscounts: [],
      debtStatus: [],
      newCharge: {
        alumno: null,
        comentarios: '',
        sub_total: 0.0,
        total_impuestos: 0.0,
        total_descuento: 0.0,
        total_adeudo: 0.0,
        descuento_aplicado: 0.0,
        moneda: 1,
        expirado: false,
        fecha_vencimiento: '',
        fecha_pronto_pago: '',
        penalizacion: null,
        descuento_pronto_pago: null,
        institucion_educativa: null,
        estatus_adeudo: null,
        autor: null,
        nombre_adeudo: '',
      },
      errors: [],
      showAlert: false,
      alertType: 'success',
      alertText: 'Registro exitoso',
      alertColor: null,
      loadingAlert: false,
      fullAddressStr: null,
      modalInvoice: false,
      page: undefined,
      student: {
        groupId: null,
        avatar: '',
        nombre: '',
        grupo: '',
        numero_credencial_alumno: '',
        datos_personales: {
          telefono_movil: '',
        },
      },
      error: null,
      loadinPage: false,
      editing: false,
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData', 'isTutor', 'selectedId']),
  },

  async created() {
    this.loadingPage = true;
    this.setLoadingState(true, 'Por favor, espere. Cargando...', 'info');
    try {
      if (this.$route.params.id) {
        this.studentId = this.validateIdRoute(this.$route.params.id);
        await Promise.all([this.loadStudentInfo(), this.setDataTable()]);
      }
    } catch (error) {
      console.error('Error in created:', error);
      this.error = 'Error al cargar datos. ';
      this.setErrorState(
        'Error al cargar datos. Por favor, inténtelo de nuevo.'
      );
    } finally {
      this.loadingPage = false;
      this.setLoadingState(false);
    }
  },
  methods: {
    canAdd: canAdd,
    canChange: canChange,
    //#region alert methods
    setLoadingState(loading, alertText = '', alertType = 'info') {
      this.loadingAlert = loading;
      this.alertText = alertText;
      this.showAlert = loading;
      this.alertType = alertType;
    },

    setErrorState(alertText) {
      this.errors.push(alertText);
      this.loadingAlert = false;
      this.alertText = alertText;
      this.alertType = 'error';
      this.showAlert = true;
    },

    setSuccessState(alertText) {
      this.loadingAlert = false;
      this.alertText = alertText || this.successAlertText;
      this.alertType = this.successAlertType;
      this.alertColor = 'success';
      this.showAlert = true;
    },

    setWarningState(warningText) {
      this.warnings.push(warningText);
      this.alertText = warningText;
      this.alertType = 'warning';
      this.alertColor = 'warning';
      this.showAlert = true;
    },
    //#endregion

    validateIdRoute(id) {
      if (!/^\d+$/.test(id)) {
        this.error = 'Formato de ID no válido.';
        throw new Error('Formato de ID no válido');
      }
      return id;
    },

    async loadStudentInfo() {
      try {
        const student = await this.studentContructor(this.studentId,'',this.institutionId);
        if (!student) {
          this.error = 'ID no válido: ';
          throw new Error('Error al cargar la información del estudiante');
        }
        this.student = student;
        const { datos_personales } = student;
        const address = await getItem(
          `/app-personas/direccion/${datos_personales.direccion}`
        );
        this.fullAddressStr = await this.fullAddress(address);
      } catch (error) {
        (this.error = 'Error al cargar la información del estudiante:'), error;
        console.error('Error al cargar la información del estudiante:', error);
      }
    },
    async createDebt() {
      if (this.$refs.form.validate()) {
        this.loadingAlert = true;
        this.showAlert = true;
        this.alertText = 'Creando cargo';
        this.newCharge.alumno = this.studentId;
        this.newCharge.institucion_educativa = this.institutionId;
        this.newCharge.autor = this.userData.id;
        this.newCharge.comentarios = this.newCharge.nombre_adeudo;
        this.newCharge.total = this.newCharge.sub_total;
        const debt = await postDebt(this.newCharge);
        this.loadingAlert = false;
        if (!debt) {
          this.alertType = 'error';
          this.alertText = 'No se pudo crear el cargo, intentelo nuevamnete';
        } else {
          this.alertType = 'success';
          this.alertColor = 'success';
          this.alertText = 'Cargo agregado exitosamente!';
        }
      }
    },
    async updateDebt() {
      if (this.$refs.form.validate()) {
        this.loadingAlert = true;
        this.showAlert = true;
        this.alertText = 'Actualizando cargo';
        // this.newCharge.alumno = this.studentId;
        // this.newCharge.institucion_educativa = this.institutionId;
        // this.newCharge.autor = this.userData.id;
        // this.newCharge.comentarios = this.newCharge.nombre_adeudo;
        // this.newCharge.total = this.newCharge.sub_total;
        this.newCharge.estatus = this.newCharge.estatus_adeudo
        const debt = await putDebt(this.newCharge);
        this.loadingAlert = false;
        if (!debt) {
          this.alertType = 'error';
          this.alertText = 'No se pudo actualizar el cargo, intentelo nuevamente';
        } else {
          this.alertType = 'info';
          this.alertColor = 'success';
          this.alertText = 'Cargo actualizado exitosamente!';
        }
      }
    },
    async saveDebt() {
      if (this.editing) {
        await this.updateDebt()
      } else {
        await this.createDebt()
      }
    },
    cleanDebt() {
      this.newCharge.id = null
      this.newCharge.comentarios = '';
      this.newCharge.sub_total = 0.0;
      this.newCharge.total_impuestos = 0.0;
      this.newCharge.total_descuento = 0.0;
      this.newCharge.total_adeudo = 0.0;
      this.newCharge.descuento_aplicado = false;
      this.newCharge.fecha_vencimiento = '';
      this.newCharge.nombre_adeudo = '';
      this.newCharge.fecha_pronto_pago = '';
      this.$refs.form.resetValidation();
      this.editing = false
    },
    calculateTotal() {
      let {
        sub_total: subtotal,
        total_impuestos: impuestos,
        total_descuento: descuento,
        total_adeudo: total,
      } = this.newCharge;

      impuestos = subtotal * 0.0;
      total = subtotal + impuestos - descuento;
      this.newCharge.total_impuestos = impuestos;
      this.newCharge.total_adeudo = total;
    },
    getFirstDayOfMonth(year, month) {
      return new Date(year, month, 1).toISOString().substring(0, 10);
    },
    getLastDayOfMonth(year, month) {
      return new Date(year, month + 1, 1 - 1).toISOString().substring(0, 10);
    },
    async setDataTable(option = 'Pendiente de pago') {
      if (option !== this.tableTitle) this.resetPagination();

      this.tableTitle = option;
      this.isCharge = false;
      this.items = [];
      this.loading = true;
      // const { id: userId } = this.userData;
      const { from: dateFrom, to: dateTo } = this.dateRange;

      const headersConfig = {
        'Pendiente de pago': [
          { text: 'Concepto', value: 'nombre_adeudo', class: 'purple--text' },
          { text: 'Estatus', value: 'estatus.dato', class: 'purple--text' },
          {
            text: 'Fecha vencimiento',
            value: 'fecha_vencimiento',
            class: 'purple--text',
          },
          {
            text: 'Fecha pronto pago',
            value: 'fecha_pronto_pago',
            class: 'purple--text',
          },
          { text: 'Monto', value: 'total_adeudo', class: 'purple--text' },
          { text: '', value: 'acciones' },
        ],
        Pagado: [
          { text: 'Fecha', value: 'fecha_elaboracion', class: 'purple--text' },
          { text: 'Concepto', value: 'categoria.dato', class: 'purple--text' },
          { text: 'Estatus', value: 'estatus.dato', class: 'purple--text' },
          { text: 'Monto', value: 'total_ingreso', class: 'purple--text' },
          { text: 'ID', value: 'id', class: 'purple--text' },
        ],
        Facturas: [
          { text: 'Nombre', value: 'cfdi_id', class: 'purple--text' },
          { text: 'Descargas', value: 'download', class: 'purple--text' },
        ],
      };

      const fetchItems = {
        'Pendiente de pago': () =>
          this.getDebts(
            this.studentId,
            this.institutionId,
            dateFrom,
            dateTo,
            this.pagination
          ),
        Pagado: () =>
          this.getIncome(
            this.studentId,
            this.institutionId,
            dateFrom,
            dateTo,
            this.pagination
          ),
        Facturas: () =>
          this.getInvoices(
            this.studentId,
            this.institutionId,
            dateFrom,
            dateTo,
            this.pagination
          ),
      };

      try {
        this.headers = headersConfig[option] || [];
        this.items = await fetchItems[option]();
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        this.loading = false;
      }
    },

    downloadDocument(url) {
      url
        ? window.document(url, '_blank')
        : console.error('La URL del documento es inválida.');
    },
    async getInvoices(student, institution, start, end, page) {
      try {
        const { data, count } = await fetchInvoiceByStudent({
          student,
          institution,
          start,
          end,
          pagination: page,
        });
        this.setPaginationCount(count);
        return data;
      } catch (error) {
        console.error('Error fetching invoices:', error);
      }
    },

    async getDebts(student, institution, start, end, page) {
      try {
        const statusDebitDefault = await this.getOrCreateStatusDebit(
          BASE_FINANCIAL_STATUS.DEBIT_STATUS,
          this.userData.id,
          this.institutionId
        );
        const { data, count } = await fetchDebtsByStudent({
          student,
          institution,
          start,
          end,
          status: statusDebitDefault.id,
          pagination: page,
        });
        this.setPaginationCount(count);
        return data;
      } catch (error) {
        console.error('Error fetching getDebts:', error);
      }
    },

    async getIncome(student, institution, start, end, page) {
      try {
        const statusIncomeDefault = await this.getOrCreateStatusIncome(
          BASE_FINANCIAL_STATUS.INCOME_STATUS,
          this.userData.id,
          this.institutionId
        );
        const { data, count } = await fetchIncomeByStudent({
          student,
          institution,
          start,
          end,
          status: statusIncomeDefault.id,
          pagination: page,
        });
        this.setPaginationCount(count);
        return data;
      } catch (error) {
        console.error('Error fetching getIncome:', error);
      }
    },

    async changePage(page) {
      this.setPaginationPage(page);
      await this.setDataTable(this.tableTitle, page);
    },

    async addNewCharge() {
      try {
        this.isCharge = true;
        const fetchIfEmpty = async (property, endpoint) => {
          if (this[property] && !this[property].length) {
            const response = await getItem(
              `${endpoint}?institucion_educativa=${this.institutionId}&estatus_sistema=true`
            );
            this[property] = response.results;
          }
        };
        await Promise.all([
          fetchIfEmpty('coins', '/app-administracion/filters/moneda'),
          fetchIfEmpty('penalties', '/app-administracion/filters/penalizacion'),
          fetchIfEmpty(
            'promptPaymentDiscounts',
            '/app-administracion/filters/descuento-pronto-pago'
          ),
          fetchIfEmpty(
            'debtStatus',
            '/app-administracion/filters/estatus-adeudos'
          ),
        ]);
      } catch (error) {
        console.error('Error occurred while adding new charge:', error);
        throw error;
      }
    },

    async edit(item) {
      // console.log(item);
      this.editing = true
      this.newCharge = {
        ...item,
        alumno: item.alumno.id,
        moneda: item.moneda.id,
        penalizacion: item.penalizacion.id,
        descuento_pronto_pago: item.descuento_pronto_pago.id,
        estatus_adeudo: item.estatus.id,
        institucion_educativa: this.institutionId,
        autor: this.userData.id
      }
      await this.addNewCharge()
    },

    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType === 'info') {
        this.returnToTable();
        this.closeAlert();
        this.cleanDebt();
      } else {
        this.closeAlert();
      }
    },
    continueAdding() {
      this.cleanDebt();
      this.closeAlert();
    },
    goToInvoiceViewer(value) {
      this.modalInvoice = true;
      this.studentResults = value;
    },
    filterButton() {
      if (!this.dateRange.from || !this.dateRange.to) return true;
      return false;
    },
    async returnToTable() {
      await this.setDataTable();
    },
    closeAlert() {
      this.errors = [];
      this.showAlert = false;
    },
    goBack() {
      // console.log('ruta anterior');
      this.$router.back();
    },
    goToStudentInfo() {
      this.$router.push({
        name: 'Editar Alumno',
        params: {
          entity: this.selectedId,
        },
      });
    },
  },
};
