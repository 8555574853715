import { getItem } from "../../../api/api-methods";

export const fetchIncomeByStudent = async ({ student, institution, status, start, end, pagination }) => {
  const encodedAlumno = encodeURIComponent(student);
  const encodedInstitucion = encodeURIComponent(institution);

  const limitFilter = pagination.limit !== null ? `&limit=${pagination.limit}` : '';
  const offsetFilter = pagination.offset !== 0 ? `&offset=${pagination.offset}` : '';

  let URL = `app-administracion/filters/ingreso?alumno=${encodedAlumno}&institucion_educativa=${encodedInstitucion}&estatus_sistema=true&estatus=${status}${limitFilter}${offsetFilter}`;

  if (start && end) URL += `&fecha_elaboracion=${start},${end}`;

  try {
    const responseData = await getItem(URL);
    return { data: responseData.results, count: responseData.count };
  } catch (error) {
    console.error('Error al obtener ingresos por alumno.', error);
  }
}



