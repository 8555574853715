import { getItem } from "../../../api/api-methods";

export const fetchInvoiceByStudent = async ({student, institution, start, end, pagination}) => {

  const limitFilter = pagination.limit !== null ? `&limit=${pagination.limit}` : '';
  const offsetFilter = pagination.offset !== 0 ? `&offset=${pagination.offset}` : '';


  const encodedAlumno = encodeURIComponent(student);
  const encodedInstitucion = encodeURIComponent(institution);
  const URL = `/app-facturacion/filters/factura?alumno=${encodedAlumno}&estatus_sistema=true&
  institucion_educativa=${encodedInstitucion}${limitFilter}${offsetFilter}&fecha_inicio=${start}&fecha_fin=${end}${limitFilter}${offsetFilter}`;
  try {
    const responseData = await getItem(URL);
    return { data: responseData.results, count: responseData.count };
  } catch (error) {
    console.error('Error al fecturas por alumno.', error);
  }
}

